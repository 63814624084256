import React from "react"
import StyledBanner from "./style/banner"
import Breadcumb from "../common/Breadcrumb"

const Banner = ({ data, image, crumbs }) => {
    return (
        <StyledBanner
            style={{
                backgroundImage: `url(${image})`
            }}
        >
            <div className="banner about">
                <div className="container">
                    <h1>{data}</h1>
                    <Breadcumb crumbs={crumbs} nested={true} />
                </div>
            </div>
        </StyledBanner>
    )
};

export default Banner