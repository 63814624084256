import React from "react"
import { FaArrowRight, FaCalendarAlt, FaClock } from "react-icons/fa"
import StyledLisitings from "./style/listings"

const Listings = ({ data }) => {
  return (
    <StyledLisitings>
      <div className="container">
        {data.map((d, i) => {
          return (
            <div className="card-wrapper" key={i}>
              <figure>
                <img src={d.image} alt="" />
              </figure>
              <div className="content">
                <ul className="meta">
                  <li>
                    <FaCalendarAlt className="icon" />
                    <span>
                      {d.date}
                    </span>
                  </li>
                  <li>
                    <FaClock className="icon" />
                    <span>
                      {d.time} Min.
                    </span>
                  </li>
                </ul>
                <h2>
                  <a href={d.slug}>
                    {d.title}
                  </a>
                </h2>
                <p>
                  {d.description}
                </p>
                <a href={d.slug}>
                  Read More
                  <FaArrowRight />
                </a>
              </div>
            </div>
          )
        })}
      </div>
    </StyledLisitings>
  )
};

export default Listings
