import { Link } from "gatsby"
import React from "react"
import tw, { styled } from 'twin.macro'
const StyledBreadcrumb = styled.nav`
${tw`z-10`}
ol {
    ${tw`flex flex-row flex-wrap text-white md:text-lg text-base`}

    li {
        span {
            ${tw`px-2`}
        }

        &.disabled {
            ${tw`text-primary`}
        }
    }
}
`;

const toKebabCase = (str) => {
    return str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((x) => x.toLowerCase())
        .join('-');
};
const Breadcumb = ({ crumbs }) => {
    function isLast(index) {
        return index === crumbs.length - 1;
    }
    return (
        <StyledBreadcrumb>
            <ol>
                {
                    crumbs.map((crumb, ci) => {
                        const disabled = isLast(ci) ? 'disabled' : '';

                        return (
                            <li
                                key={ci}
                                className={`${disabled}`}
                            >
                                {!disabled ? (
                                    <Link className={`z-10 btn btn-link ${disabled}`} to={crumb.link} >
                                        {crumb.name}
                                    </Link>
                                ) : (
                                    <>
                                        {crumb.name}
                                    </>
                                )}
                                {!disabled ? <span>/</span> : ''}
                            </li>
                        );
                    })
                }
            </ol>
        </StyledBreadcrumb >
    )
};

export default Breadcumb
