import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Listings from "../components/blog/Listings"
import bannerImg from "../assets/images/blog-banner.jpg"
import { SEO } from "../components/seo"
import Banner from "../components/common/Banner"

const BlogList = ({ data }) => {
  const blogList = [];
  data.allMarkdownRemark.edges.forEach(edge => {
    blogList.push({
      date: edge.node.frontmatter.date,
      description: edge.node.frontmatter.description,
      slug: edge.node.frontmatter.slug,
      title: edge.node.frontmatter.title,
      image: edge.node.frontmatter.featuredImage ? edge.node.frontmatter.featuredImage.publicURL : "",
      time: edge.node.timeToRead
    })
  });
  const crumbs = [
    { name: 'Home', link: '/' },
    { name: 'Blog', link: '' }
  ]
  return (
    <Layout>
      <Banner
        data="Inpirations"
        image={bannerImg}
        crumbs={crumbs}
      />
      <Listings data={blogList} />
    </Layout>
  )
};

export const Head = () => (
  <SEO
    title="Blog - Housefull Events & Hospitality Management"
    description="Explore the topics of South Indian traditional weddings , attires, techniques of photography and videography, follow ups, hospitality/ hotel consultancy and services."
  />
)

export default BlogList

export const blogListQuery = graphql`
  query blogListQuery{
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            description
            featuredImage {
                publicURL
            }
          }
          timeToRead
        }
      }
    }
  }
`