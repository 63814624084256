import tw, { styled } from 'twin.macro';

const StyledLisitings = styled.section`
${tw`pt-8 pb-4 bg-primary bg-opacity-5 relative overflow-hidden`}
.container {
    ${tw`grid grid-cols-1 lg:(px-16 grid-cols-2 py-5 gap-8) xl:(px-24 grid-cols-3) container mx-auto px-4 gap-6 bg-transparent text-black`} 
    .card-wrapper {
        ${tw`bg-white shadow-lg rounded-lg flex flex-col overflow-hidden relative [border: 1px solid #ddd]`}

        figure {
            ${tw`w-full overflow-hidden relative`}

            img {
                ${tw`w-full h-60 [transform: translateZ(0)] [transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms]`}
            }

            &::after {
                ${tw`[content: ''] h-full w-full bottom-0 top-0 absolute z-[1] [background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5))]`}
            }
        }

        .content {
            ${tw`flex flex-col p-6 gap-3 text-[#4a4a4a] h-[calc(100% - 15rem)] relative`}

            h2 {
                ${tw`text-xl font-medium`}

                a {
                    ${tw`text-primary-dark`}
                }
            }

            a {
                ${tw`text-secondary flex flex-row gap-2 items-center`}
            }

            ul.meta {
                ${tw`flex flex-row justify-between`}
                li {
                    ${tw`flex flex-row gap-1 items-center text-dark-muted`}
                }
            }
        }

        &:hover {
            ${tw`[transform: translate3d(0, -2px, 0)]`}
            figure {
                img {
                    ${tw`[transform: scale(1.12)]`}
                }
            }
        }
    }
}
`;

export default StyledLisitings;