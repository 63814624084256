import tw, { styled } from 'twin.macro';

const StyledBanner = styled.section`
${tw`bg-center bg-cover`}
.banner {
    ${tw`md:(pt-32 pb-28) pt-24 pb-12 relative overflow-hidden z-[1]`}
    .container {
        ${tw`flex flex-col gap-y-6 items-center justify-center container mx-auto px-5 z-[1]`} 
        h1 {
            ${tw`md:text-3xl text-xl font-extrabold text-center p-2 bg-primary text-dark z-[1]`}
        }
    }
    &:before {
        ${tw`w-full h-full left-0 top-0 absolute z-0 [content: ""] [background-color: rgba(0,0,0,.7)]`}
    }
}
`;

export default StyledBanner;
